import { z } from 'zod'

export const TEMPORARY_APP_ROUTER_URL_PREFIX = '/50cad7c41437a446'

const VERCEL_ENV = z
  .enum(['development', 'preview', 'production'])
  .parse(process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV)

export const ENV_TAG = (
  {
    production: 'prod',
    preview: 'test',
    development: 'dev',
  } as const
)[VERCEL_ENV]

/** Used to store a logged out user's country preference if they change it */
export const ANONYMOUS_USER_COUNTRY_COOKIE_NAME = 'q_anonymous_user_country'
